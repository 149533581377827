<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-title style="font-size: 1.5rem;">Users</va-card-title>
      <va-card-content>
        <div class="row">
          <va-select
            class="flex mb-2 md2"
            :options="filterTypes"
            v-model="filterType"
            label="Filter Types"
            placeholder="Select Type"
            :clearable="false"
          />
          <div class="flex mb-2 md3" v-if="filterType === 'Temporal'">
            <div class="row">
              <va-date-input
                class="flex mb-2 md8"
                v-model="filterDateStart"
                label="Start Date"
              />
              <va-time-input
                class="flex mb-2 md4"
                v-model="filterTimeStart"
              />
            </div>
            <div class="row" style="margin-top: -1.5rem; margin-bottom: -1.5rem;">
              <va-date-input
                class="flex mb-2 md8"
                v-model="filterDateEnd"
                label="End Date"
              />
              <va-time-input
                class="flex mb-2 md4"
                v-model="filterTimeEnd"
              />
            </div>
          </div>
          <va-select
            class="flex mb-2 md2"
            :options="accesses"
            v-model="filterText"
            label="Accesses"
            placeholder="Select Access"
            :clearable="false"
            v-if="filterType === 'Access'"
          />
          <va-input
            class="flex mb-2 md2"
            placeholder="Filter..."
            v-model="filterText"
            v-on:keyup.enter="addFilter"
            v-else-if="filterType === 'All' || filterType === 'Name' || !filterType"
          />
          <div class="flex mb-2 md3 text-left" style="padding-right: 4rem;" v-if="(filterType !== 'Temporal' && filterText.length > 0) || (filterType === 'Temporal' && filterDateStart)">
            <va-icon :size="'2rem'" :name="'fa4-plus-square'" class="link" @click="addFilter" />
          </div>
          <div class="flex mb-2 md5 text-right" style="padding-right: 4rem;" v-if="selectedItems.length > 0 && (filterType !== 'Temporal' && filterText.length > 0)">
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-right: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" v-if="access !== 'User'" />
          </div>
          <div class="flex mb-2 md4 text-right" style="padding-right: 4rem;" v-else-if="selectedItems.length > 0 && (filterType === 'Temporal' && filterDateStart)">
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-right: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" v-if="access !== 'User'" />
          </div>
          <div class="flex mb-2 md7 text-right" style="padding-right: 4rem;" v-else-if="selectedItems.length > 0 && (filterType === 'Temporal' && !filterDateStart)">
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-right: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" v-if="access !== 'User'" />
          </div>
          <div class="flex mb-2 md8 text-right" style="padding-right: 4rem;" v-else-if="selectedItems.length > 0">
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-right: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" v-if="access !== 'User'" />
          </div>
        </div>
        <div class="row">
          <div class="flex xs12">
            <va-chip outline class="mb-2 mr-2" color="primary" v-for="(filter, i) in filters" v-bind:key="`chip${i}`">
              {{ filter }}&nbsp;<va-icon :size="'1rem'" :name="'fa4-remove'" style="cursor: pointer;" @click="removeFilter(i)" />
            </va-chip>
          </div>
        </div>
        <div class="row">
          <div class="flex xs12">
            <va-alert class="mt-3" color="primary" outline  v-if="selectedItemsAll.length > 0">
              <span>
                Number of selected items:
                <va-chip class="ml-2 chip-hover" @click="clearSelection">{{ selectedItemsAll.length }}</va-chip>
              </span>
            </va-alert>
          </div>
        </div>
        <va-data-table
          :items="filtered"
          :columns="columns"
          :per-page="perPage"
          :current-page="1"
          :selectable="true"
          :loading="isUsersLoading"
          @sorted="sortChange"
          @selection-change="selectionChange"
          v-model="selectedItems"
        >
          <template #headerPrepend>
            <th colspan="4" style="font-size: 1rem; text-align: left;">
              <router-link :to="{ name: 'user', params: { id: 'add' }}" class="add" v-if="access !== 'User'">
                + Add User
              </router-link>
            </th>
          </template>
          <template #bodyAppend>
            <tr>
              <td colspan="4" class="table-example--pagination">
                <va-pagination
                  v-model="currentPage"
                  input
                  :pages="pages"
                />
              </td>
              <td colspan="3" class="table-example--pagination">
                <va-select
                  style="width: 8rem;"
                  v-model="perPage"
                  :label="'Per Page'"
                  :options="perPageOptions"
                  noClear
                />
              </td>
            </tr>
          </template>
          <template #cell(uid)="{ source: uid }">
            <router-link :key="uid" :to="{ name: 'user', params: { id: uid }}">
              <va-icon :name="'fa4-eye'"/>
            </router-link>
            <va-icon :name="'fa4-trash'" style="padding-left: 1rem; cursor: pointer; color: red;" @click="removeModal(uid, name)" v-if="user_id !== uid" />
          </template>
        </va-data-table>
        <va-alert class="mt-3" border="left" v-if="filters.length > 0">
          <span>
            Number of filtered items:
            <va-chip>{{ filteredCount }}</va-chip>
          </span>
        </va-alert>
      </va-card-content>
    </va-card>

    <va-modal
      v-model="removeModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Remove a user</h2>
      </template>
      <slot>
        <div>Do you want to remove {{ removeName }}?</div>
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="removeModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="danger" @click="removeModalConfirm">Delete</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="removeMultiModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Remove users</h2>
      </template>
      <slot>
        <div>Do you want to remove the following Users?</div>
        <br />
        <p v-for="removeMultiName in removeMultiNames">{{ removeMultiName }}</p>
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="removeMultiModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="danger" @click="removeMultiModalConfirm">Delete All</va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

export default {
  mounted: async function () {
    this.loader = this.$loading.show()
    try {
      // Load a list of users
      this.loadUsers()
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: '/pages/users' } })
    }
    this.loader.hide()
  },
  data () {
    return {
      loader: null,
      token: this.$store.state.token,
      user_id: this.$store.state.uid,
      access: this.$store.state.access,
      users: [],
      filtered: [],
      usersObject: {},
      selectedItems: [],
      selectedItemsAll: [],
      columns: [
        {
          key: 'name',
          label: 'Name',
          headerTitle: 'Name',
          sortable: true
        },
        {
          key: 'username',
          label: 'Username',
          headerTitle: 'Username',
          sortable: true
        },
        {
          key: 'access',
          label: 'Access',
          headerTitle: 'Access',
          sortable: true
        },
        {
          key: 'uid',
          label: 'Action',
          headerTitle: 'Action'
        }
      ],
      filterText: '',
      filterDateStart: null,
      filterDateEnd: null,
      filterTimeStart: null,
      filterTimeEnd: null,
      filters: localStorage.getItem('users_filters') ? localStorage.getItem('users_filters').split(',') : [],
      filtersTypes: localStorage.getItem('users_filtersTypes') ? localStorage.getItem('users_filtersTypes').split(',') : [],
      filterTypes: ['All', 'Name', 'Temporal', 'Access'],
      filterType: null,
      accesses: ['Administrator', 'Manager', 'User'],
      editModalShow: false,
      perPage: localStorage.getItem('users_perPage') ? parseInt(localStorage.getItem('users_perPage')) : 10,
      perPageOptions: [5, 10, 25, 50, 100, 500, 1000],
      currentPage: localStorage.getItem('users_currentPage') ? parseInt(localStorage.getItem('users_currentPage')) : 1,
      removeModalShow: false,
      removeId: null,
      removeName: null,
      removeMultiModalShow: false,
      removeMultiNames: [],
      newId: null,
      pages: 0,
      sortBy: 'created_at',
      sortOrder: 'desc',
      isUsersLoading: false,
      newLoad: false
    }
  },
  computed: {
    filteredCount () {
      return this.filtered.length
    }
  },
  watch: {
    currentPage (newCurrentPage) {
      localStorage.setItem('users_currentPage', newCurrentPage)
      this.loadUsers()
    },
    perPage (newPerPage) {
      localStorage.setItem('users_perPage', newPerPage)
      this.loadUsers()
    }
  },
  methods: {
    loadUsers: async function (filtering = false) {
      this.isUsersLoading = true
      let selectedItemsAll = []
      if (!filtering) {
        selectedItemsAll = this.selectedItemsAll
      } else {
        this.selectedItems = []
      }
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const filter_types = []
        const filter_values = []
        // Prepare filters
        this.filtersTypes.forEach((type, i) => {
          switch (type) {
            case 'All':
              filter_types.push('all')
              filter_values.push(this.filters[i])
              break
            case 'Name':
              filter_types.push('name')
              filter_values.push(this.filters[i])
              break
            case 'Temporal':
              filter_types.push('created_at')
              if (this.filters[i].split('to').length <= 1) {
                const start_time = moment(`${this.filters[i]} 00:00`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                const end_time = moment(`${this.filters[i]} 23:59`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                filter_values.push(`${start_time} to ${end_time}`)
              } else {
                const start_time = moment(this.filters[i].split(' to ')[0], 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                const end_time = moment(this.filters[i].split(' to ')[1], 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                filter_values.push(`${start_time} to ${end_time}`)
              }
              break
            case 'Access':
              filter_types.push('access')
              filter_values.push(this.filters[i])
              break
            default:
              break
          }
        })

        let url = `${process.env.VUE_APP_API_URL}/api/users?page_no=${this.currentPage}&per_page=${this.perPage}&sort_by=${this.sortOrder === 'asc' ? this.sortBy : `-${this.sortBy}`}`
        if (filter_types.length > 0) {
          url = `${url}&filter_types=${filter_types.toString()}&filter_values=${filter_values.toString()}`
        }
        // Load a list of users
        const users = await axios.get(url, { headers })
        this.users = await Promise.all(users.data.users.map((user) => {
          user.created_at = moment.utc(user.created_at).toDate()
          user.created_at = moment(user.created_at).local().format('YYYY-MM-DD hh:mm a')
          user.name = `${user.first_name} ${user.last_name}`
          this.usersObject[user.uid] = `${user.first_name} ${user.last_name}`
          return user
        }))
        this.filtered = this.users
        this.pages = users.data.total_pages
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: '/pages/users' } })
      }
      this.isUsersLoading = false
      this.newLoad = true
      this.selectedItemsAll = selectedItemsAll
    },
    removeModal (id) {
      this.removeId = id
      this.removeName = this.usersObject[id]
      this.removeModalShow = true
    },
    removeModalConfirm: async function () {
      this.loader = this.$loading.show()
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Delete a user
        await axios.delete(`${process.env.VUE_APP_API_URL}/api/users/${this.removeId}`, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'User deleted successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        // Load list of users again
        this.loadUsers(true)

        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
      } catch (error) {
        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
        this.$router.push({ name: 'login', query: { redirect: '/pages/users' } })
      }
      this.loader.hide()
    },
    removeMultiModal: async function () {
      this.removeMultiNames = await Promise.all(this.selectedItemsAll.map((selectedItem) => selectedItem.name))
      this.removeMultiModalShow = true
    },
    removeMultiModalConfirm: async function () {
      if (!this.loader) {
        this.loader = this.$loading.show()
      }
      try {
        // Delete users
        await Promise.all(this.selectedItemsAll.map((selectedItem) => this.removeItem(selectedItem.uid)))
        // Show success message
        this.$vaToast.init({
          message: 'Sessions deleted successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        this.loadUsers(true)

        this.selectedItems = []
        this.selectedItemsAll = []
        this.removeMultiNames = []
        this.removeMultiModalShow = false
      } catch (error) {
        this.selectedItems = []
        this.selectedItemsAll = []
        this.removeMultiNames = []
        this.removeMultiModalShow = false
        this.$router.push({ name: 'login', query: { redirect: `/pages/users` } })
      }
      if (this.loader) {
        this.loader.hide()
      }
    },
    removeItem (id) {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      return axios.delete(`${process.env.VUE_APP_API_URL}/api/users/${id}`, { headers })
    },
    addFilter: function () {
      switch (this.filterType) {
        case 'All':
        case 'Name':
        case 'Access':
          if (this.filterText && this.filterText.length > 0) {
            this.filters.push(this.filterText)
            this.filtersTypes.push(this.filterType)
          }
          break
        case 'Temporal':
          if (this.filterDateStart) {
            const startDate = new Date(this.filterDateStart).toISOString().split('T')[0]
            const endDate = this.filterDateEnd ? new Date(this.filterDateEnd).toISOString().split('T')[0] : null
            const startTime = this.filterTimeStart ? `${this.filterTimeStart.toString().split(' ')[4].split(':')[0]}:${this.filterTimeStart.toString().split(' ')[4].split(':')[1]}` : '00:00'
            const endTime = this.filterTimeEnd && this.filterDateEnd ? `${this.filterTimeEnd.toString().split(' ')[4].split(':')[0]}:${this.filterTimeEnd.toString().split(' ')[4].split(':')[1]}` : '23:59'
            let tempDate = `${startDate} ${startTime}`
            if (endDate) {
              tempDate = `${tempDate} to ${endDate} ${endTime}`
            } else {
              tempDate = startDate
            }
            this.filters.push(tempDate)
            this.filtersTypes.push(this.filterType)
          }
          break
        default:
      }
      localStorage.setItem('users_filters', this.filters)
      localStorage.setItem('users_filtersTypes', this.filtersTypes)
      this.filterText = ''
      this.filterType = ''
      this.loadUsers(true)
    },
    removeFilter: function (index) {
      this.filters.splice(index, 1)
      this.filtersTypes.splice(index, 1)
      localStorage.setItem('users_filters', this.filters)
      localStorage.setItem('users_filtersTypes', this.filtersTypes)
      this.loadUsers(true)
    },
    sortChange (sorted) {
      if (sorted.sortBy && sorted.sortingOrder && (this.sortBy !== sorted.sortBy || this.sortOrder !== sorted.sortingOrder)) {
        this.sortBy = sorted.sortBy
        this.sortOrder = sorted.sortingOrder
        this.loadUsers()
      }
    },
    selectionChange (selectedItems) {
      if (selectedItems.currentSelectedItems.length > selectedItems.previousSelectedItems.length) {
        const difference = selectedItems.currentSelectedItems.filter(x => !selectedItems.previousSelectedItems.includes(x))
        difference.forEach((item) => {
          this.selectedItemsAll.push(item)
        })
      } else {
        if (!this.newLoad) {
          const difference = selectedItems.previousSelectedItems.filter(x => !selectedItems.currentSelectedItems.includes(x))
          difference.forEach((item) => {
            _.remove(this.selectedItemsAll, item)
          })
        } else {
          this.users.forEach((user) => {
            this.selectedItemsAll.forEach((userSelected) => {
              if (user.uid === userSelected.uid) {
                this.selectedItems.push(user)
              }
            })
          })
          this.newLoad = false
        }
      }
    },
    clearSelection () {
      this.selectedItems = []
      this.selectedItemsAll = []
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .table-example--pagination {
    text-align: center;
    text-align: -webkit-center;
    padding-top: 1rem;
  }

  .add:hover {
    text-decoration: underline;
  }

  .link {
     cursor: pointer;
     color: var(--va-primary);
  }

  .link:hover {
    text-decoration: underline;
  }

  .va-data-table {
    --va-data-table-thead-color: var(--va-primary);
  }

  .va-pagination__input {
    background-color: var(--va-white);
  }

  .va-alert {
    background-color: var(--va-white) !important;
  }

  .va-modal__dialog {
    background-color: var(--va-white);
    color: var(--va-dark);
  }

  .va-date-picker {
    --va-date-picker-text-color: var(--va-pure-dark);
  }

  .chip-hover:hover {
    cursor: pointer;
    background: var(--va-danger) !important;
  }
</style>
